.aboutNameBubble:hover {
  text-decoration: underline;
  cursor: pointer;
}


.icon-container svg {
  width: 24px;
  height: 24px;
  transition: transform 0.2s ease-in-out;
}

.icon-container:hover svg {
  transform: scale(1.2);
}





.user-profile-picture-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: visible !important; 
  border-radius: '2rem' !important;
}

.user-profile-picture {
  position: absolute;
  top: -3.75rem; /* Adjust to control the overlap */
  border-radius: 50%;
}

.user-card-body {
  padding-top: 60px; /* Adjust based on the size of the image and how much it's wedged into the card */
}

