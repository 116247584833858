.captain-name {
    cursor: pointer;
}

.captain-name:hover {
    text-decoration: underline;
}


.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  .shimmer-header1 {
    font-weight: bold;
    color: #ffffff;
    background-color: #ae8625 !important; /* Base color if shimmer effect fails */
    background-image: linear-gradient(
      to right,
      #ae8625 0%,
      #f7ef8a 20%,
      #d2ac47 40%,
      #edc967 100%
    );
    background-repeat: no-repeat;
    background-size: 800% 100%;
    animation: shimmer 5s linear infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -500% 0;
    }
    100% {
      background-position: 500% 0;
    }
  }


  .shimmer-header3 {
    font-weight: bold;
    color: #ffffff; /* White text */
    background-color: #996515; /* Deep gold for a rich backdrop, though not intended to be visible */
    background-image: linear-gradient(
      to right,
      #996515 0%,      /* Rich bronze gold */
      #ffd700 10%,     /* Bright gold */
      #c5a253 20%,     /* Muted gold */
      #ffd700 30%,     /* Bright gold */
      #c5a253 40%,     /* Muted gold */
      #ffd700 50%,     /* Bright gold */
      #c5a253 60%,     /* Muted gold */
      #ffd700 70%,     /* Bright gold */
      #c5a253 80%,     /* Muted gold */
      #ffd700 90%,     /* Bright gold */
      #996515 100%     /* Rich bronze gold */
    );
    background-repeat: no-repeat;
    background-size: 100% 100%; /* Adjusted for a continuous flow */
    animation: shimmer 3s linear infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0% 0;
    }
  }
  
  .shimmer-header4 {
    font-weight: bold;
    color: #ffffff; /* White text for elegance */
    background-color: #a47c48; /* Deep, rich gold as a base */
    background-image: linear-gradient(
      135deg, /* Diagonal gradient for added sophistication */
      #a47c48 0%,      /* Rich base gold */
      #dbc68d 25%,     /* Soft, light gold */
      #b4985a 50%,     /* Warm medium gold */
      #dbc68d 75%,     /* Soft, light gold again */
      #a47c48 100%     /* Back to rich base gold */
    );
    background-repeat: no-repeat;
    background-size: 200% 200%; /* Allows for a broad, smooth transition */
    animation: shimmer 10s ease-in-out infinite;
  }
  
  @keyframes shimmer {
    0%, 100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 100%;
    }
  }




  .shimmer-header5 {
    font-weight: bold;
    color: #ffffff; /* White text for clarity and elegance */
    background-color: #8c6d1f; /* Dark, rich gold as a foundational base */
    background-image: linear-gradient(
      135deg, /* Diagonal gradient for sophistication */
      #8c6d1f 0%,      /* Very dark gold for depth */
      #ffd700 25%,     /* Bright, vibrant gold for light */
      #a47c48 50%,     /* Medium, balanced gold for warmth */
      #ffd700 75%,     /* Bright, vibrant gold again for highlight */
      #8c6d1f 100%     /* Back to very dark gold for richness */
    );
    background-repeat: no-repeat;
    background-size: 200% 200%; /* Expanded size for smooth transitions */
    animation: shimmer 10s ease-in-out infinite;
  }
  
  @keyframes shimmer {
    0%, 100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 100%;
    }
  }
  
  .shimmer-header6 {
    font-weight: bold;
    color: #ffffff; /* White text for maximum contrast and elegance */
    background-color: #ffd700; /* Vibrant gold as a base */
    background-image: linear-gradient(
      135deg, /* Diagonal gradient for added depth */
      #ffd700 0%,      /* Vibrant gold */
      #ffefb8 25%,     /* Light, almost creamy gold */
      #ffd700 50%,     /* Vibrant gold for the core */
      #ffefb8 75%,     /* Light, almost creamy gold again */
      #ffd700 100%     /* Vibrant gold to complete the loop */
    );
    background-repeat: no-repeat;
    background-size: 200% 200%; /* Enhanced size for a smooth gradient flow */
    animation: shimmer 10s ease-in-out infinite;
  }
  
  @keyframes shimmer {
    0%, 100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 100%;
    }
  }
  

  .shimmer-header7 {
    font-weight: bold;
    color: #ffffff; /* Crisp white text for perfect readability */
    background-color: #ffcc00; /* Base gold color, bright and clean */
    background-image: linear-gradient(
      135deg, /* Adding a touch of sophistication with angle */
      #ffcc00 0%,    /* Bright, pure gold */
      #ffff33 25%,   /* Brighter, almost metallic gold for highlight */
      #ffcc00 50%,   /* Returning to bright, pure gold */
      #ffff33 75%,   /* Brighter, almost metallic gold again */
      #ffcc00 100%   /* Consistent with the start for a seamless loop */
    );
    background-repeat: no-repeat;
    background-size: 200% 200%; /* Ensuring a wide range for the gradient to travel */
    animation: shimmer 10s ease-in-out infinite; /* Smooth, captivating motion */
  }
  
  @keyframes shimmer {
    0%, 100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 100%;
    }
  }
  

  .shimmer-header {
    font-weight: bold;
    color: #ffffff; /* Ensuring white text is visible */
    background-color: #ffcc00; /* Gold background */
    background-image: linear-gradient(
      135deg,
      #d4af37 25%, /* Slightly darker gold for contrast */
      #fffacd 50%, /* Light gold to enhance text visibility */
      #d4af37 75% /* Slightly darker gold for contrast */
    );
    background-repeat: no-repeat;
    background-size: 200% 200%; /* Large size for a smooth transition */
    animation: shimmer 5s ease-in-out infinite;
  }
  
  @keyframes shimmer {
    0%, 100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 100%;
    }
  }


  