.circle-button {
    position: relative;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .circle-button img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .required-star {
    position: absolute;
    top: -5px;
    right: -5px;
    color: red;
    font-size: 16px;
  }
  