





/* Settings.css */

.custom-phone-input .PhoneInputCountrySelect {
    display: true; /* Hide the country flag dropdown */
}

.custom-phone-input .PhoneInputInput {
    width: 100%; /* Full width */
    height: calc(1.5em + 0.75rem + 2px); /* Same height as other inputs */
    padding: 0.375rem 0.75rem; /* Same padding as other inputs */
    font-size: 1rem; /* Same font size as other inputs */
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem; /* Same border radius as other inputs */
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-phone-input:focus-within .PhoneInputInput {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
